.__tab__container {
  // .ant-tabs {
  //   display: flex;
  //   justify-content: space-between; /* Dàn đều các tab */
    
  // }
  .ant-tabs-nav-list {
    width: 100%;
  }
  .ant-tabs-tab {
    flex: 1;
    justify-content: center;
  }
  .tabs__custom__dropdown {
    .ant-tabs-tab {
      margin: 0 !important;
      .ant-tabs-tab-btn {
        color: #646970;
        font-size: 16px;
      }
    }
  }
}
