.checkbox_setting {
  .ant-checkbox-group {
    display: block;
    .ant-checkbox-wrapper {
      display: block;
      margin-bottom: 0.5rem;
    }
  }
}
.even-row {
  background-color:  #F8FAFD;
}
.even-row .ant-table-cell-fix-left, 
.even-row .ant-table-cell-fix-right {
  background-color: #F8FAFD !important; /* Màu nền cho cột cố định trong row chẵn */
}

.odd-row {
  background-color:  #ffffff;
}
.odd-row .ant-table-cell-fix-left, 
.odd-row .ant-table-cell-fix-right {
  background-color: #ffffff !important; /* Màu nền cho cột cố định trong row chẵn */
}
